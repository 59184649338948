
body {
	margin: 0;
	/* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif; */
		font-family: 'Montserrat', sans-serif;
		font-weight: 300;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

@font-face {
	font-family: 'Montserrat Regular';
	src: local("Montserrat Regular'"),
	url("./fonts//Montserrat-Regular.woff") format("woff");
}

@font-face {
	font-family: 'Montserrat Bold';
	src: local("Montserrat Bold"),
	url("./fonts//Montserrat-Bold.woff") format("woff");
}

@font-face {
	font-family: 'Montserrat Light';
	src: local("Montserrat Light"),
	url("./fonts//Montserrat-Light.woff") format("woff");
}

@font-face {
	font-family: 'EVERETT-Regular';
	src: local("EVERETT-Regular"),
	url("./fonts//EVERETT-Regular.woff") format("woff");
}


code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}


html,
body,

#root {
	height: 100%;

	background-color: rgb(5, 4, 8);

	text-align: center;
	font-size: 12px;
	letter-spacing: .15em;

	overflow: clip;
}

a, a:visited {
	text-decoration: none;
}

hr {
	border: 0;
	height: 0;
	border-top: 1px rgba( 255, 255, 255, .5 ) solid;
}

:where(ul[class]) {
	margin: 0;
	padding: 0;
	list-style-type: none;
}


[hidden] { display: none !important }




.map-container {
  height: 400px;
  width: 400px;
}
 
.sidebar {
background-color: rgba(35, 55, 75, 0.9);
color: #ffffff;
padding: 6px 12px;
font-family: monospace;
z-index: 1;
position: absolute;
top: 0;
left: 0;
margin: 12px;
border-radius: 4px;
}

/* Mapbox */

.mapboxgl-ctrl-logo {
	display: none !important;
}


/* Nav Control */
.mapboxgl-ctrl-bottom-right {
    bottom: 25px !important;
}
.mapboxgl-ctrl-group:not(:empty) {
    box-shadow: none !important;
}
.mapboxgl-ctrl-group {
    /* border-radius: 4px; */
    background: transparent !important;
}

.mapboxgl-ctrl-group button {
	width: 70px !important;
	height: 70px !important;
}
.mapboxgl-ctrl button.mapboxgl-ctrl-compass .mapboxgl-ctrl-icon {
	background-image:  url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 14.045 62.602'%3E%3Cpath d='M6.13 18.358L.156 39.033a4.003 4.003 0 000 2.222L6.13 61.931a.93.93 0 001.787 0l5.972-20.676a4.003 4.003 0 000-2.222L7.916 18.358a.93.93 0 00-1.787 0z' fill='%23deaf5f'/%3E%3Ccircle cx='7.022' cy='40.144' r='2.018' fill='%23ecebe5'/%3E%3Cpath d='M2.143 0h.822l8.098 10.21V0h.839v11.736h-.654L2.982 1.308v10.428h-.839z' fill='%23454545'/%3E%3C/svg%3E") !important;
}